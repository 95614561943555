import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA(state, payload) {
      return axios.get(`calendar/?start=${payload.start}&end=${payload.end}`)
    },
    // PATCH_DATA(state, payload) {
    //   return axios.patch('me/profile/', payload)
    // },
  }
}
