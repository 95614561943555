import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA() {
      return axios.get('users/')
    },
  }
}
