import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(store, payload) {
      return axios.get(`contracts/supplier/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`)
    },
  }
}
