import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from '@/http/axios/axios'
import profile from './profile'
import auth from './auth'
import contracts from './contracts'
import calendar from './calendar'
import users from './users'
import supplier from './supplier'
import todo from './todo'
// import dict from './dict'
// import search from './search'
// import teams from './teams'
// import team from './team'
// import events from './events'
// import mkpCards from './mkp-card'
// import membership from './membership'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  namespaced: true,
  state: {
    sidebar: false,
    sidebarOpen: false,
    firstName: '',
    lastName: '',
    avatar: '',
    meProfile: {}
  },
  mutations: {
    sidebarToggle(state) {
      state.sidebar = !state.sidebar
    },
    sidebarMobileToggle(state) {
      state.sidebarOpen = !state.sidebarOpen
    },
    setFirstName (state, payload) {
      state.firstName = payload
    },
    setLastName (state, payload) {
      state.lastName = payload
    },
    setAvatar(state, payload) {
      state.avatar = payload
    },
    setProfile(state, payload) {
      state.meProfile = payload
    },
  },
  actions: {
    POST_FILE(state, payload) {
      return axios.post('files/', payload)
    },
    POST_FORGOT(state, data) {
      return axios.post('password_reset/', data)
    },
    POST_CONFIRM(state, payload) {
      return axios.post(`password_reset/confirm/${payload.token}/`, payload.data)
    },
  },
  modules: {
    // dict,
    // search,
    auth,
    profile,
    contracts,
    calendar,
    users,
    supplier,
    todo
    // teams,
    // team,
    // events,
    // mkpCards,
    // membership,
  }
})
