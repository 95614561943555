import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA(state, id) {
      return axios.get(`task/${id}/`)
    },
    POST_DATA(state, payload) {
      return axios.post(`task/`, payload)
    },
    PUT_DATA(state, payload) {
      return axios.put(`task/${payload.id}/`, payload)
    },
    PATCH_DATA(state, payload) {
      return axios.patch(`task/${payload.id}/`, payload.params)
    },
    DELETE_DATA(state, id) {
      return axios.delete(`task/${id}/`)
    },
  }
}
