<template>
  <div class="table-head">
    <div class="table-head__part">
      <span class="table-head__text">Количество комментариев на странице:</span>
      <Dropdown
          class="dropdown-select"
          :options="limit_options"
          :selected="limit_selected"
          :closeOnOutsideClick="true"
          @updateOption="onUpdate"
      >
      </Dropdown>
    </div>
    <div class="table-head__part">
      Показаны комментарии <span class="table-head__count">{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из
      <span class="table-head__count">{{ all }}</span>
    </div>
  </div>
</template>

<script>
import Dropdown from 'vue-dropdowns'

export default {
  name: 'AppCommentHead',
  components: {
    Dropdown
  },
  props: {
    page: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    all: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      limit_selected: { name: 5 },
      limit_options: [
        { name: 5 },
        { name: 10 },
        { name: 15 },
        { name: 20 },
      ],
    }
  },
  computed: {
    lastPage() {
      return (this.$props.page * this.limit_selected.name) >= this.$props.all
    }
  },
  methods: {
    onUpdate(data) {
      this.limit_selected.name = data.name
      this.$emit('update-limiter', data.name)
    },
    returnStart() {
      if (!this.lastPage) return (this.$props.page * this.$props.count) - (this.$props.count - 1)
      return (this.$props.page * this.limit_selected.name) - this.limit_selected.name + 1
    },
    returnEnd() {
      if (!this.lastPage) return this.returnStart() + this.$props.count - 1
      return this.$props.all
    },
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/common/index.scss"

.table-head
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 20px

.table-head__part
  display: flex
  align-items: center
  gap: 5px

.table-head__count
  color: $color-theme
  font-weight: 600
</style>
