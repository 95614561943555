import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA(state, id) {
      return axios.get(`/task/${id}/todo/`)
    },
    POST_DATA(state, payload) {
      return axios.post(`/task/${payload.id}/todo/`, payload.name)
    },
    PUT_DATA(state, payload) {
      return axios.put(`/task/${payload.id}/todo/${payload.todo_id}/`, payload.params)
    },
    DELETE_DATA(state, payload) {
      return axios.delete(`/task/${payload.id}/todo/${payload.todo_id}/`)
    },
  }
}
