<template>
  <v-popover
    :placement="placement"
  >
    <button type="button" class="tooltip-target b3">
      <slot name="icon" />
    </button>
    <template slot="popover">
      <slot name="content" />
    </template>
  </v-popover>
</template>

<script>
export default {
  name: 'AppTooltip',
  props: {
    placement: {
      type: String,
      default: 'left-start'
    }
  },
}
</script>
