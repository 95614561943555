<template>
  <div class="grid" :class="[`grid--${col}`, {'grid--indent': indent}]">
    <div v-for="item in arr" :key="item" class="grid__item">
      <slot :name="`item-${item}`" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppGrid',
  props: {
    col: {
      type: String,
      default: '2',
      validator: value => {
        return value <= 6
      }
    },
    items: {
      type: String,
      default: '2'
    },
    indent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      arr: []
    }
  },
  mounted() {
    for (let i = 1; i <= this.items; i++) {
      this.arr.push(i)
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.grid
  display: grid
  gap: 30px

.grid--2
  grid-template-columns: repeat(2, minmax(45%, 1fr))

  +max-w($tablet)
    grid-template-columns: repeat(1, 1fr)

.grid--3
  grid-template-columns: repeat(3, 1fr)

  +max-w($tablet)
    grid-template-columns: repeat(1, 1fr)

.grid--4
  grid-template-columns: repeat(4, 1fr)

  +max-w($tablet)
    grid-template-columns: repeat(1, 1fr)

.grid--5
  grid-template-columns: repeat(5, 1fr)

  +max-w($tablet)
    grid-template-columns: repeat(1, 1fr)

.grid--6
  grid-template-columns: repeat(6, 1fr)

  +max-w($tablet)
    grid-template-columns: repeat(1, 1fr)

.grid--indent
  margin-bottom: 30px
</style>
