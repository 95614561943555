<template>
  <input
      @input="$emit('input', $event.target.value)"
      :value="value"
      :type="type"
      class="input"
      :class="{'input--error': error}"
  />
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    value: [String, Number],
    type: {
      default: 'text',
      validator: value => {
        return value === 'text' || value === 'email' || value === 'password' || value === 'url' || value === 'number'
      }
    },
    error: Boolean,
  },
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.input
  width: 100%
  padding: 12px 25px 11px 20px
  border: 1px solid #c7cfdd
  border-radius: 7px
  color: $color-text-base
  background-color: #fff
  font-size: 16px
  font-weight: 400
  line-height: 20px
  transition: border-color .3s

  &::placeholder
    color: $color-text-placeholder

  &:focus
    border-color: $color-theme

  &[readonly]
    pointer-events: none
    border-color: #eff1f5

.input--error
  border-color: $color-error
</style>
