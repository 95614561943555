import Vue from 'vue'
import VueRouter from 'vue-router'
import ProfileIndex from '@/views/profile/Index'
import MyTasksIndex from '@/views/profile/my-tasks/Index'
import Events from '@/views/events/Index'
import store from '@/store'
import { isLoggedIn } from 'axios-jwt'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login'),
    meta: {
      layout: 'welcome',
      title: 'Вход'
    },
  },
  // {
  //   path: '/',
  //   redirect: { name: 'index' }
  // },
  {
    path: '/',
    component: () => import('@/views/profile/Index'),
    redirect: { name: 'calendar' },
    children: [
      {
        path: '',
        name: 'calendar',
        component: () => import('@/views/profile/Calendar'),
        meta: {
          title: 'Календарь'
        },
      }
    ]
  },
  {
    path: '/tasks',
    component: MyTasksIndex,
    name: 'tasks',
    redirect: { name: 'tasks-all' },
    children: [
      {
        path: '',
        name: 'tasks-all',
        component: () => import('@/views/profile/my-tasks/List'),
        meta: {
          title: 'Мои задачи'
        },
      },
      {
        path: 'view/:id',
        name: 'task',
        component: () => import('@/views/profile/my-tasks/Detail'),
        meta: {
          title: 'Просмотр задачи'
        },
      },
      {
        path: 'create',
        name: 'create-task',
        component: () => import('@/views/profile/my-tasks/Create'),
        meta: {
          title: 'Добавления задачи'
        },
      },
      {
        path: '/tasks/update/:id',
        name: 'edit-task',
        component: () => import('@/views/profile/my-tasks/Edit'),
        meta: {
          title: 'Изменнение задачи'
        },
      },
    ]
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: () => import('@/views/contracts/Index'),
    redirect: {name: 'contracts-list'},
    children: [
      {
        path: '',
        name: 'contracts-list',
        component: () => import('@/views/contracts/List'),
        meta: {
          title: 'Контракты'
        },
      },
      {
        path: 'create',
        name: 'contracts-create',
        component: () => import('@/views/contracts/Create'),
        meta: {
          title: 'Добавление контракта'
        },
      },
      {
        path: 'view/:id',
        name: 'contracts-view',
        component: () => import('@/views/contracts/View'),
        meta: {
          title: 'Просмотр контракта'
        },
      },
      {
        path: 'update/:id',
        name: 'contracts-edit',
        component: () => import('@/views/contracts/Edit'),
        meta: {
          title: 'Редактирование контракта'
        },
      }
    ]
  },
  {
    path: '',
    component: Events,
    name: 'events',
    children: [
      {
        path: '/events',
        name: 'events-all',
        component: () => import('@/views/events/List'),
        meta: {
          title: 'События'
        },
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const loggedIn = isLoggedIn()

  if (to.fullPath.includes('membership')) {
    store.dispatch('membership/POST_CHECK_CARD')
      .then(() => { next({ name: 'membership-my' }) })
      .catch(() => { next({ name: 'membership-link' }) })
  }

  if (
    (to.name === 'login' ||
      to.name === 'registration' ||
      to.name === 'password-forgot' ||
      to.name === 'password-confirm' ||
      to.name === '404') &&
    loggedIn
  ) {
    next({ name: 'index' })
  } else {
    next()
  }
})

export default router
