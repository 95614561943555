<template>
  <div class="comments">
    <div
      v-for="comment in comments"
      :key="comment.id"
      class="comments__item"
    >
      <div class="comments__avatar">
        <img v-if="comment.created_by.avatar" :src="comment.created_by.avatar" alt="">
        <img v-else src="@/assets/img/comment-slug.svg" alt="">
      </div>
      <div class="comments__content">
        <div class="comments__top">
          <span class="comments__author">{{ comment.created_by.full_name }}</span>
          <span class="comments__date">{{ comment.created_at }}</span>
        </div>
        <p class="comments__text">{{ comment.comment }}</p>
        <template v-if="comment.file">
          <span class="comments__desc">Прикрепленный файл:</span>
          <button @click="onDownload(comment.file_obj.file, comment.file_obj.file_name)" class="link">{{ comment.file_obj.file_name }}</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppComments',
  props: {
    comments: {
      type: Array,
    }
  },
  methods: {
    async onDownload(url, name) {
      const response = await fetch(url)
      const blob = await response.blob()
      const link = document.createElement('a')
      link.style = 'display: none'
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      URL.revokeObjectURL(link.href)
    },
  }
}
</script>

<style scoped lang="sass">
.comments__item
  display: flex
  gap: 16px
  align-items: flex-start
  margin-bottom: 10px
  padding: 20px 16px
  border-radius: 10px
  background-color: #f8fdff

  &:last-child
    margin-bottom: 0

.comments__avatar
  overflow: hidden
  width: 75px
  height: 75px
  border-radius: 50%

  img
    width: 100%
    height: 100%
    object-fit: cover

.comments__top
  margin-bottom: 10px

.comments__author
  margin-right: 12px
  font-weight: 600

.comments__date
  color: #7c8db0

.comments__text:not(:last-child)
  margin-bottom: 20px

.comments__desc
  display: block
  margin-bottom: 12px
  color: #7c8db0

</style>
