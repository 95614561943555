<template>
  <div class="form-group">
    <div v-if="label" class="form-group__top">
      <label :for="labelFor" class="form-group__label">{{ label }}<sup v-if="required">*</sup></label>
      <div v-if="$slots.additional" class="form-group__additional">
        <slot name="additional" />
      </div>
    </div>
    <div class="form-group__body">
      <slot name="default" />
      <div v-if="$slots.right_place" class="form-group__right">
        <slot name="right_place" />
      </div>
    </div>
    <div v-if="$slots.error" class="form-group__error">
      <slot name="error" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppFormGroup',
    props: {
      labelFor: String,
      label: String,
      required: Boolean,
    },
  }
</script>

<style scoped lang="sass">
  @import "@/assets/common/index.scss"

  .form-group
    margin-bottom: 30px

    &:last-child
      margin-bottom: 0

  .form-group--checkbox
    margin-bottom: 15px

    &:last-child
      margin-bottom: 15px

    .form-group__body
      display: flex

  .form-group--bottom-place
    position: relative

    +max-w($mobile_lg)
      padding-bottom: 30px

  .form-group__top
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 8px

  .form-group__label
    font-size: 16px
    font-weight: 600

    &[for]
      cursor: pointer

  .form-group__error
    color: $color-error
    font-size: 14px

    div:first-child
      margin-top: 5px

  .form-group__body
    position: relative

  .form-group__text
    margin-top: 10px
    font-size: 14px

  .form-group__right
    position: absolute
    top: 50%
    right: 20px
    line-height: 0
    transform: translateY(-50%)

    button
      line-height: 0

  .form-group__additional
    display: flex
    align-items: center
    gap: 10px

    +max-w($mobile_lg)
      position: absolute
      bottom: 0
      left: 0

  .form-group__oye
    display: grid
    grid-template-columns: 1fr auto

    .btn
      margin-left: 20px

      +max-w($mobile_md)
        position: absolute
        top: -32px
        right: 0
        margin-left: 0
</style>
