import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST(store, payload) {
      return axios.get(`contracts/?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}`)
    },
    GET_DATA(store, id) {
      return axios.get(`contracts/${id}/`)
    },
    POST_DATA(store, payload) {
      return axios.post('contracts/', payload)
    },
    DELETE_DATA(store, id) {
      return axios.get(`contracts/${id}/`)
    },
    PUT_DATA(store, payload) {
      return axios.put(`contracts/${payload.id}/`, payload.form)
    },
    GET_CITY() {
      return axios.get('dict/contracts/city/')
    },
    GET_DEPARTMENTS() {
      return axios.get('departments/')
    },
    GET_ALLOCATION() {
      return axios.get('dict/contracts/type-allocation/')
    },
    GET_STAGE() {
      return axios.get('dict/contracts/stage/')
    },
    GET_ENFORCEMENT() {
      return axios.get('dict/contracts/enforcement-way/')
    },
    GET_USERS() {
      return axios.get('users/')
    },
    GET_CATEGORY() {
      return axios.get('dict/contracts/category/')
    },
    GET_SUBCATEGORY() {
      return axios.get('dict/contracts/sub-category/')
    },
    GET_COMMENTS(store, payload) {
      return axios.get(`contracts/${payload.id}/comment/?page=${payload.page}&page_size=${payload.page_size}`)
    },
    POST_COMMENT(store, payload) {
      return axios.post(`contracts/${payload.id}/comment/`, payload.form)
    }
  }
}
