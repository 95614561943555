<template>
  <header class="header">
    <div class="header__part">
      <router-link :to="{ name: '' }" class="header__link">
        <img src="@/assets/img/header-logo.svg" alt="Header logo">
      </router-link>
    </div>
    <div class="header__part">
      <button
        @click="sidebarMobileOpen"
        class="header__burger"
      >
        <img src="@/assets/img/header-burger.svg" alt="Header burger">
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {

    }
  },
  methods: {
    sidebarMobileOpen() {
      this.$store.commit('sidebarMobileToggle')
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.header
  position: absolute
  left: 0
  top: 0
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  padding: 30px 40px

  +min-w($laptop_sm)
    display: none

  +max-w($mobile_xl)
    padding: 15px

.header__part
  position: relative
  display: flex
  align-items: center
  flex-shrink: 0

.header__block
  display: flex
  align-items: center
  flex-shrink: 0

.header__search
  width: 100%
  max-width: 674px

  +max-w($mobile_xl)
    max-width: 100%

.header__notification
  position: relative
  margin-right: 25px
  cursor: pointer

  img
    display: block

  +max-w($mobile_lg)
    margin-right: 15px

.header__count
  position: absolute
  top: 0
  right: -2px
  width: 15px
  height: 15px
  border-radius: 50%
  color: #fff
  background-color: #FF9416
  font-size: 10px
  text-align: center

.header__burger
  line-height: 0

.header__link
  line-height: 0
</style>
