import axios from '@/http/axios/axios'
import jwt from '@/http/requests/auth'
import { isLoggedIn, setAuthTokens } from 'axios-jwt'

export default {
  namespaced: true,
  state: {
    isUserLoggedIn: () => {
      return isLoggedIn()
    }
  },
  actions: {
    LOGIN_JWT({ commit }, payload) {
      return new Promise((resolve, reject) => {
        jwt.login(payload.user_details.email, payload.user_details.password)
          .then(response => {
            if (response.data.access) {
              setAuthTokens({
                accessToken: response.data.access,
                refreshToken: response.data.refresh
              })
              resolve(response)
            } else {
              reject({message: 'Неверное имя или пароль'})
            }
          })
          .catch(() => {
            reject({message: 'Неверное имя или пароль'})
          })
      })
    },
  }
}
