<template>
  <div class="cells" :class="[ positions[position], {'cells--indent': indent} ]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppCells',
  props: {
    position: {
      type: String,
      default: 'end',
    },
    indent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      positions: {
        start: 'cells--start',
        end: 'cells--end',
        center: 'cells--center',
        between: 'cells--between'
      },
    }
  },
}
</script>

<style scoped lang="sass">
.cells
  display: flex
  gap: 10px 20px
  align-items: center
  flex-wrap: wrap

.cells--end
  justify-content: flex-end

.cells--start
  justify-content: flex-start

.cells--center
  justify-content: center

.cells--between
  justify-content: space-between

.cells--indent
  margin: 20px 0

  &:first-child
    margin-top: 0
</style>
