import axios from '@/http/axios/axios'
import tasks from './my-tasks'
import task from './task'

export default {
  namespaced: true,
  actions: {
    GET_DATA() {
      return axios.get('me/')
    },
    PATCH_DATA(state, payload) {
      return axios.patch('me/', payload)
    },
    GET_CONFIRM_EMAIL() {
      return axios.get('me/send-confirm-email/')
    },
  },
  modules: {
    tasks,
    task
  }
}
