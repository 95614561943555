import axios from '@/http/axios/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA(state, payload) {
      const start_date = payload.filters.start_date ? `&start_date=${payload.filters.start_date}` : ''
      const end_date = payload.filters.end_date ? `&end_date=${payload.filters.end_date}` : ''
      const user = payload.filters.user ? `&user=${payload.filters.user}` : ''
      const expired = payload.filters.expired ? `&expired=${payload.filters.expired}` : ''
      const status = payload.status ? `&status=${payload.status}` : ''
      const search = payload.search ? `&search=${payload.search}` : ''
      return axios.get(`task/?page=${payload.page}&page_size=${payload.page_size}${status}${start_date}${end_date}${user}${expired}${search}`)
    },
    // PATCH_DATA(state, payload) {
    //   return axios.patch('me/profile/', payload)
    // },
  }
}
